<template>
  <div>
    <h5 class="p-t-20 text-center bold p-b-10 auth-title">{{ $t('auth.login page') }}</h5>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off" @submit.prevent="passes(login)">
        <HeaderNotice ref="notice"></HeaderNotice>
        <AppInput inputStyle="normal" name="login_email" type="email" rules="required"
                  :label="$t('common.mail address')"
                  v-model="email"></AppInput>
        <AppInput inputStyle="normal" name="password" :type="[showNewPassword ? 'text' : 'password']" rules="required" :label="$t('common.password')"
                  v-model="password">
          <template v-slot:append>
            <i style="font-size: 25px; cursor: pointer" @click="showNewPassword = !showNewPassword"
               :class="['fa', showNewPassword ? 'fa-eye' : 'fa-eye-slash']"></i>
          </template>
        </AppInput>
        <div class="text-center">
          <router-link class="forgot-password color-active" :to="{name: forgotPasswordRoute}">
            {{ $t('auth.forgot_password_label') }}
          </router-link>
        </div>
        <div>
          <button class="btn btn-complete btn-auth" type="submit">{{ $t('auth.login') }}</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ROUTES from "@consts/routes";
import HeaderNotice from "@components/_common/HeaderNotice";
import AuthConfig from "@consts/auth";

export default {
  data() {
    let resetPassword = AuthConfig[this.$store.state.auth.currentAuth.role].routes.reset_password
    let forgotPassword = AuthConfig[this.$store.state.auth.currentAuth.role].routes.forgot_password
    return {
      resetPasswordRoute: resetPassword,
      forgotPasswordRoute: forgotPassword,
      email: this.$route.query.email,
      password: null,
      error: false,
      showNewPassword: false
    }
  },
  mounted() {
    if (this.$route.query.verified === 'yes') {
      this.$refs.notice.setSuccess(this.$t('auth.verify ok'));
    }
    if (this.$route.query.changePass === 'yes') {
      this.$refs.notice.setSuccess(this.$t('auth.reset pass ok'));
    }
  },
  components: {HeaderNotice},
  methods: {
    async login() {
      let role = AuthConfig[this.$store.state.auth.currentAuth.role].role_id
      let homeRoute = AuthConfig[this.$store.state.auth.currentAuth.role].routes.bookings;
      const res = await this.$auth.login({
        email: this.email,
        password: this.password,
        role: role
      }, this.$store.state.setting.authType)
      if (!res.hasErrors()) {
        this.$router.pushByName(homeRoute)

        // Old logic of base
        // const prevRouteName = this.$store.state.setting.prevRouter.name;
        // if (prevRouteName && prevRouteName !== this.resetPasswordRoute && prevRouteName !== this.forgotPasswordRoute) {
        //   this.$router.push(this.$store.state.setting.prevRouter)
        // } else {
        //   this.$router.pushByName(homeRoute)
        // }
      } else {
        this.$refs.notice.setError(res.data.msg ? res.data.msg : this.$t('common.error'));
      }
    },
  }
}
</script>
<style scoped>
.forgot-password {
  text-decoration: underline !important;
}
</style>
